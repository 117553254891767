<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <br>
    <div
      class="d-flex justify-content-center align-items-center mt-2"
    >
      <h3>
        <strong>Promotion</strong>
      </h3>
    </div>

    <br>
    <b-row>
      <b-col
        v-for="(campaign, key) in campaigns"
        :key="key"
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div
              class="d-flex  align-items-center justify-content-between"
            >
              <div
                class="d-flex  justify-content-center  flex-column"
                style="gap:8px;"
              >
                <h4>{{ campaign.campaign_name }} <span class="fontsize-sm"> ({{ campaign.promotion.promotion_name }}) </span> </h4>
                <h5 class=" m-0 ">
                  <span class="text-success"> {{ campaign.campaign_count }} / {{ campaign.target }} </span>  <span class="fontsize-sm"> {{ campaign.campaign_percentage }}</span>
                </h5>
                <p>Channel: {{ campaign.channel }}<br>From: {{ campaign.date_from }} To: {{ campaign.date_to }} </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <br>

    <div
      class="d-flex justify-content-center align-items-center mt-2"
    >
      <h3>
        <strong>Marketing Program</strong>
      </h3>
    </div>

    <br>
    <b-row>
      <b-col
        v-for="(marketingProgram, key) in marketingPrograms"
        :key="key"
        cols="12"
        sm="6"
      >
        <iq-card>
          <template v-slot:body>
            <div
              class="d-flex  align-items-center justify-content-between"
            >
              <div
                class="d-flex  justify-content-center  flex-column"
                style="gap:8px;"
              >
                <h4>{{ marketingProgram.marketing_program_name }}</h4>
                <h5 class=" m-0 ">
                  <span class="text-success"> {{ marketingProgram.mkt_program_count }} / {{ marketingProgram.target }} </span>  <span class="fontsize-sm"> {{ marketingProgram.mkt_program_percentage }}</span>
                </h5>
                <p> From: {{ marketingProgram.date_from }} To: {{ marketingProgram.date_to }} </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" />
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SupervisorDashboardService } from '@/services'

export default {
  name: 'SupervisorDashboard',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      campaigns: [],
      marketingPrograms: []
    }
  },

  mounted () {
    core.index()
    this.getCampaigns()
    this.getMarketingPrograms()
  },

  methods: {
    async getCampaigns () {
      await SupervisorDashboardService.getCampaigns().then(({ data }) => {
        this.campaigns = data
      })
    },

    async getMarketingPrograms () {
      await SupervisorDashboardService.getMarketingPrograms().then(({ data }) => {
        this.marketingPrograms = data
      })
    },

    refreshStatistics () {
      this.$refs.table.refresh()
    }
  }
}
</script>
